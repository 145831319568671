<template>
  <div class="about">
    <Header />
    <div class="swipers">
      <img src="../images/pc/players.jpg" alt="" />
      <div class="words">
        <h1>FBS -- 莱斯特城足球俱乐部首席合作伙伴</h1>
        <h3>
          莱斯特城足球俱乐部和FBS如今正携手共进，致力于提高大众对达成财务自由的可能的认知。
        </h3>
      </div>
    </div>
    <div class="worsname">
      <div class="worsnameTop">
        <div class="leftlog">
          <img
            src="../images/pc/Joint_FBS-horizontal-FBS_Lleicester-zh-Color-Positive.svg"
            alt=""
          />
        </div>
        <div class="rightwords">
          <h4>我们的合作伙伴关系是两大决策力的协同合作</h4>
          <p>
            这次与莱斯特城的合作也是我们反馈我们交易商朋友们的又一种方式。我们的客户是我们的一切。所以我们会了解大家的价值观和兴趣，我们也知道你们中的许多人对足球充满了热情。
          </p>
          <p>
            因此我们成为了莱斯特城足球俱乐部的首席合作伙伴，并计划以全力激励我们的客户朋友们、给予大家最大程度的回馈。
          </p>
        </div>
      </div>
    </div>
    <div class="match">
      <img src="../images/pc/sdasd.png" alt="" />
    </div>
    <div class="match">
      <img src="../images/pc/aeq.png" alt="" />
    </div>
    <div class="miangroup">
      <div class="miangroup_left" style="margin-right: 15px">
        <h2 style="font-size: 50px; margin-top: 80px">主场</h2>
        <p style="font-size: 22px">
          莱斯特城球队拥有梦幻的王权球场。这是他们的主场，在这里他们拿下了2016年英超联赛冠军。
        </p>
        <p style="font-size: 22px">
          而作为一名FBS的交易商，您可以自主选择可以给予您永远前行信心的赛场。在FBS旗下各平台中，交易商可以享受到大量各式各样的功能，保证您可以拥有最佳的交易体验。您的主场就在这里，它会助您勇攀峰顶！
        </p>
      </div>
      <div class="miangroup_right">
        <img src="../images/pc/ground-players@1x.jpg" alt="" />
      </div>
    </div>
    <div class="theNext">
      <h2 style="font-size: 50px">接下来的计划</h2>
      <p style="font-size: 22px">
        作为LCFC的合作伙伴，我们有计划在之后的比赛和好礼派送活动中持续派发各类福利。
      </p>
    </div>
    <div class="dontGo">
      <img class="clothes" src="../images/pc/lcoht.png" alt="" />
    </div>
    <div class="soccer">
      <img src="../images/pc/ball@2x.png" alt="" />
      <h3>成为主导力量一份子 -- 成为FBS一份子</h3>
      <div class="openAcc">
        <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">开设账户</a>
      </div>
      <div style="height: 50px"></div>
    </div>
    <Footer />
    <Help />
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";
import Help from "../components/help.vue";
export default {
  components: {
    Footer,
    Header,
    Help,
  },
};
</script>

<style  scoped>
.about {
  /* color: #000; */
  min-width: 1200px;
}
.swipers {
  position: relative;
  top: -80px;
  color: #09544f;
}
.swipers > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
  max-height: 550px;
}
.words {
  position: absolute;
  top: 40%;
  left: 3%;
}
.words > h1 {
  margin: 0;
  font-family: "Adieu", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 1.25;
  width: 700px;
  letter-spacing: 0;
}
.worsname {
  width: 1200px;
  margin: 0 auto;
}
.worsnameTop {
  display: flex;
}
.leftlog {
  width: 400px;
  height: 158px;
}
.leftlog > img {
  width: 100%;
}
.rightwords {
  margin-left: 30px;
  color: #09544f;
}
.rightwords > h4 {
  margin: 0;
  padding: 0;
  font-size: 36px;
}
.rightwords > p {
  font-size: 20px;
}
.worsnameBottom {
  width: 860px;
  margin: 0 auto;
  background-color: #09544f;
  height: 300px;
}
.match > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.miangroup {
  width: 1200px;
  margin: 0 auto;
  color: #09544f;
  display: flex;
  margin-top: 50px;
}
.theNext {
  color: #09544f;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
}
.dontGo {
  width: 100%;
  height: 250px;
  background-color: #00be40;
  position: relative;
  margin-top: 120px;
}
.clothes {
  position: absolute;
  top: -80px;
  left: 10%;
}
.soccer {
  background-color: #09544f;
  margin-top: 60px;
  width: 100%;
  position: relative;
  color: #fff;
}
.soccer > img {
  width: 120px;
  position: absolute;
  top: -60px;
  right: 10%;
}
.soccer > h3 {
  font-size: 50px;
  width: 500px;
  text-align: center;
  margin: 0 auto;
  padding-top: 60px;
}
.openAcc {
  font-size: 30px;
  width: 400px;
  height: 80px;
  background-color: #ff6633;
  border-radius: 10px;
  text-align: center;
  line-height: 80px;
  margin: 0 auto;
  margin-top: 30px;
}
</style>

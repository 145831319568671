<template>
  <div class="mobieLeft">
    <div class="head">
      <div class="left">
        <img
          style="width: 36px; margin-left: 10px"
          src="../images/pc/logo.svg"
          alt=""
        />
      </div>
      <div class="right"><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">登 录</a></div>
    </div>
    <div class="midcontent">
      <img src="../images/mobie/sdcxc.png" alt="" />
    </div>
    <div class="midcontent">
      <img src="../images/mobie/fxdda.png" alt="" />
    </div>
    <el-carousel height="400px">
      <el-carousel-item v-for="item in inglist" :key="item.id">
        <img class="banner" :src="item.url" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="midcontent">
      <img src="../images/mobie/jkdk.png" alt="" />
    </div>
    <div class="midcontent mton">
      <img src="../images/mobie/dkld.png" alt="" />
    </div>
    <div class="miangroup">
      <div class="miangroup_con">
        <h2>主场</h2>
        <p>
          莱斯特城球队拥有梦幻的王权球场。这是他们的主场，在这里他们拿下了2016年英超联赛冠军。
        </p>
        <p>
          而作为一名FBS的交易商，您可以自主选择可以给予您永远前行信心的赛场。在FBS旗下各平台中，交易商可以享受到大量各式各样的功能，保证您可以拥有最佳的交易体验。您的主场就在这里，它会助您勇攀峰顶！
        </p>
        <img src="../images/mobie/ground-players@2x.jpg" alt="" />
      </div>
    </div>
    <div class="thenext">
      <h2>接下来的计划</h2>
      <p>
        作为LCFC的合作伙伴，我们有计划在之后的比赛和好礼派送活动中持续派发各类福利。
      </p>
    </div>
    <el-carousel height="350px">
      <el-carousel-item v-for="item in listimg" :key="item.id">
        <img class="banner" :src="item.url" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="opecc">
      <div style="width: 90%; margin: 0 auto">
        <div style="height: 20px"></div>
        <h2>成为主导力量一份子 -- 成为FBS一份子</h2>
        <div class="openAcc">
          <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
            >开设账户</a
          >
        </div>
      </div>
    </div>
    <MobileEnd />
    <HelpMoble />
  </div>
</template>

<script>
import HelpMoble from "../components/helpMobie.vue";
import MobileEnd from "../components/mobileEnd.vue";
export default {
  components: {
    HelpMoble,
    MobileEnd,
  },
  data() {
    return {
      inglist: [
        { id: 1, url: require("../images/mobie/spwe.png") },
        { id: 2, url: require("../images/mobie/swper1.png") },
      ],
      listimg: [
        { id: 1, url: require("../images/mobie/ldw.png") },
        { id: 2, url: require("../images/mobie/sdeks.png") },
        { id: 3, url: require("../images/mobie/fdfxs.png") },
      ],
    };
  },
};
</script>

<style  scoped>
.mobieLeft {
}
.head {
  height: 50px;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right {
  height: 50px;
  width: 80px;
  background-color: #507500;
  margin-right: 10px;
  line-height: 50px;
  text-align: center;
}
.midcontent > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.banner {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.mton {
  position: relative;
  top: -10px;
}
.miangroup {
  width: 95%;
  color: #09544f;
  margin: 0 auto;
}
.miangroup_con > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.thenext {
  width: 95%;
  color: #09544f;
  margin: 0 auto;
  text-align: center;
}
.opecc {
  height: 200px;
  background-color: #09544f;
}
.opecc {
  text-align: center;
}
.openAcc {
  font-size: 24px;
  width: 30vw;
  height: 60px;
  background-color: #ff6633;
  border-radius: 10px;
  text-align: center;
  line-height: 60px;
  margin: 0 auto;
}
</style>
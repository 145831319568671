<template>
  <div class="mobieright">
    <div class="head">
      <div class="left">
        <img
          style="width: 36px; margin-left: 10px"
          src="../images/pc/logo.svg"
          alt=""
        />
      </div>
      <div class="right"><a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365">登 录</a></div>
    </div>
    <div class="banner">
      <img src="../images/mobie/esdvvs.png" alt="" />
    </div>
    <div class="log">
      <img
        style="width: 200px; margin-top: 30px"
        src="../images/pc/cn_joint-logo-horizontal-black.svg"
        alt=""
      />
      <p>
        2020年1月1日起，FBS的官方合作单位中再添重量级新伙伴，世界最著名团队之一
        - FC Barcelona。
      </p>
    </div>
    <div class="backimg">
      <img src="../images/mobie/dderf.png" alt="" />
      <div class="backimgcomt">
        <div class="backimgcomt_conte">
          <h2>亚洲娱乐合作伙伴<br />bet365</h2>
          <h2 style="font-size: 20px">亚洲娱乐请到官方合作伙伴bet365注册</h2>
          <div style="height: 20px"></div>
          <div class="openAcc">
            <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
              >现在就注册</a
            >
          </div>
          <div style="height: 30px"></div>
        </div>
      </div>
    </div>
    <h3
      class="tutlea"
      style="font-size: 40px; text-align: center; color: #1a235c"
    >
      FBS与FC Barcelona为何如此契合
    </h3>
    <div class="line"></div>
    <h4 class="titilename">成就源自不断的努力</h4>
    <div class="detiilas">
      冠军的成长都离不开全力以赴的努力。交易想要成功也需要大量的练习与自我提升，不断克服困难，才能最终有机会来庆祝得之不易的荣光。我们为我们的客户提供自学的途径与方式，以及各类支持与激励。为更好的成绩
      - 为完美的交易！
    </div>
    <div class="imgclass">
      <img src="../images/mobie/bg-achievements-mobile_v3@2x.jpg" alt="" />
    </div>
    <div class="imgclass">
      <img src="../images/mobie/wee.png" alt="" />
    </div>
    <h4 class="titilename">改革创新</h4>
    <div class="detiilas">
      足球已经通过各种游戏、流媒体赛事转播，与广告等被数字化；而交易则通过各类APP征服了移动界。FBS以创新为动力，在数字化移动化方向努力寻求未来发展。我们推出了品牌交易平台，它是您管理FBS帐户及相关必要交易信息的高效数字助理，也为广大想要复制顶尖市场业绩者的交易商朋友们提供了一个良好的环境。
    </div>
    <div class="imgclass">
      <img src="../images/mobie/dsdwe.png" alt="" />
    </div>
    <p
      style="
        font-size: 21px;
        color: #1a235c;
        font-weight: 600;
        line-height: 25px;
        text-align: center;
      "
    >
      我们都是有着明确理念、以成功为一致目标的团体，相信我们的合作关系可以天长地久硕果累累。
    </p>
    <div class="imgclass">
      <img src="../images/mobie/stadium@2x.jpg" alt="" />
    </div>
    <div class="nowReg">
      <div style="height: 20px"></div>
      <div class="logoreg">
        <img src="../images/mobie/cn_joint-logo-horizontal-white.svg" alt="" />
      </div>
      <p style="text-align: center; font-size: 34px">
        亚洲客户请到官方合作伙伴bet365娱乐
      </p>
      <div class="openAcc">
        <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
          >现在就注册</a
        >
      </div>
      <img class="lastimg" src="../images/mobie/glory_v3@2x.png" alt="" />
    </div>
    <MobileEnd />
    <HelpMoble />
  </div>
</template>

<script>
import HelpMoble from "../components/helpMobie.vue";
import MobileEnd from "../components/mobileEnd.vue";

export default {
  components: {
    HelpMoble,
    MobileEnd,
  },
};
</script>

<style  scoped>
.mobieright {
  box-sizing: border-box;
}
.head {
  height: 50px;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.right {
  height: 50px;
  width: 80px;
  background-color: #507500;
  margin-right: 10px;
  line-height: 50px;
  text-align: center;
}
.banner > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.log {
  width: 90%;
  margin: 0 auto;
  color: #637176;
  text-align: center;
  font-size: 20px;
}
.backimg > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.backimgcomt {
  width: 100vw;
  background-color: #080d33;
  position: relative;
  top: -10px;
}
.backimgcomt_conte > h2 {
  margin: 0;
  font-size: 40px;
  text-align: center;
}

.backimgcomt_conte {
  width: 90%;
  margin: 0 auto;
}
.openAcc {
  font-size: 24px;
  width: 70vw;
  height: 60px;
  background-color: #ff6633;
  text-align: center;
  line-height: 60px;
  margin: 0 auto;
}
.lastimg {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.line {
  width: 35vw;
  height: 1px;
  background-color: #64a33a;
  margin: 0 auto;
  margin-bottom: 20px;
}
.titilename {
  color: #000;
  text-align: center;
  font-size: 22px;
}
.detiilas {
  width: 95%;
  margin: 0 auto;
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
.imgclass {
}
.imgclass > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.contact {
  color: #869ca8;
  font-size: 16px;
  font-weight: normal;
  background-color: #000;
}
.contact > h3 {
  margin: 0;
}
.ending {
  width: 100vw;
  background-color: #384147;
  font-size: 12px;
  color: #6b6b6b;
}
.ending > p {
  margin: 0 auto;
  width: 95%;
}
.nowReg {
  background: linear-gradient(99.37deg, #201258 0.06%, #581251 96.6%);
  width: 100vw;
}
.logoreg {
  width: 200px;
  margin: 0 auto;
}
</style>
<template>
  <div class="frienship">
    <Header />
    <div class="swipers">
      <img src="../images/pc/banner_v3.jpg" alt="" />
      <div class="words">
        <h1>FBS - FC Barcelona官方交易合作伙伴</h1>
      </div>
    </div>
    <div class="mianContents">
      <div class="mianContents_head">
        <div class="mianContents_head_top">
          <div class="logimg"></div>
          <p>
            2020年1月1日起，FBS的官方合作单位中再添重量级新伙伴，世界最著名团队之一
            - FC Barcelona。
          </p>
        </div>
        <h2 class="title">亚洲娱乐合作伙伴<br />bet365</h2>
        <div class="titleword">
          <p>亚洲娱乐请到官方合作伙伴bet365注册</p>
        </div>
        <div class="nowACC">
          <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
            >现在就注册</a
          >
        </div>
        <div style="height: 50px"></div>
      </div>
      <div class="contentAll">
        <img src="../images/pc/fdff.png" alt="" />
      </div>
      <div class="contentAll">
        <img src="../images/pc/gfgv.png" alt="" />
      </div>
      <div class="contentAll">
        <img src="../images/pc/kljsd.png" alt="" />
      </div>
      <div class="contentAllLast">
        <img src="../images/pc/cgffd.png" alt="" />
        <div class="nowACCOther">
          <p>亚洲客户请到官方合作伙伴bet365娱乐</p>
          <div class="nowReg">
            <a href="https://www.bblc0020.com/?c=ODOFR&type=NewBET365"
              >现在就注册</a
            >
          </div>
        </div>
      </div>
      <div class="share">
        <p>与朋友分享：</p>
        <div>
          <a href="">
            <img
              style="width: 30px; margin-left: 10px"
              src="../images/pc/8b86e8c5c83a4784aaaff03aa83bb92f.jpeg"
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
    <Footer />
    <Help />
  </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";
import Help from "../components/help.vue";

export default {
  components: {
    Footer,
    Header,
    Help,
  },
};
</script>

<style  scoped>
.frienship {
  min-width: 1200px;
}
.swipers {
  position: relative;
  top: -80px;
  color: #000;
}
.swipers > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
  max-height: 550px;
}
.words {
  position: absolute;
  top: 40%;
  left: 3%;
}
.words > h1 {
  color: #fff;
  margin: 0;
  font-family: "Adieu", Arial, sans-serif;
  font-weight: normal;
  font-size: 56px;
  line-height: 1.25;
  letter-spacing: 0;
}
.mianContents {
  background-color: #384147;
  margin-top: -85px;
}
.mianContents_head {
  margin: 0 auto;
  width: 60%;
  height: 100%;
  background-image: url("../images/pc/bg-brief_v3.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  top: -50px;
}
.mianContents_head_top {
  width: 80%;
  color: #728183;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}
.logimg {
  width: 200px;
  height: 200px;
  background-image: url("../images/pc/cn_joint-logo-horizontal-black.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 30px;
}
.title {
  font-size: 40px;
  margin-top: 30%;
  margin-left: 5%;
}
.titleword {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 24px;
}
.nowACC {
  width: 200px;
  background-color: #ff6633;
  margin: 0 auto;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
}
.contentAll {
  width: 60%;
  margin: 0 auto;
}
.contentAll > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.contentAllLast {
  width: 60%;
  margin: 0 auto;
  position: relative;
}
.contentAllLast > img {
  display: inline-block;
  height: auto;
  max-width: 100%;
  min-width: 100%;
}
.nowACCOther {
  position: absolute;
  bottom: 2%;
  left: 10%;
}
.nowACCOther > p {
  font-size: 26px;
  padding: 0;
  margin: 0;
}
.nowReg {
  width: 200px;
  background-color: #ff6633;
  margin: 0 auto;
  text-align: center;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  margin-top: 10px;
}
.share {
  width: 60%;
  margin: 0 auto;
  background-color: #fff;
  height: 80px;
  color: #000;
  position: relative;
  top: -20px;
}
</style>